import {
  IApiContentResponseModel,
  IApiMediaWithCropsResponseModel,
  PagedIApiContentResponseModel,
} from '@ingeniorforeningen/umbraco-client';
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';
import Layout from '@/layouts/Layout';
import { UmbracoGrid } from '@ingeniorforeningen/figurine-cms';
import { TBreadcrumbsProps } from '@ingeniorforeningen/figurine-core';
import { nextPathToUrl, urlToNextPath } from '@/lib/utilities';
import { getAllPages, getPageData } from '../lib/apiHelpers';

const Slug = ({
  data: { page, settings, breadcrumbs, taggedNodes },
}: {
  data: {
    page: IApiContentResponseModel & {
      image: IApiMediaWithCropsResponseModel;
      childNodeList?: PagedIApiContentResponseModel;
    };
    settings: IApiContentResponseModel;
    breadcrumbs: TBreadcrumbsProps['items'];
    taggedNodes: PagedIApiContentResponseModel;
  };
}) => <Layout page={page} settings={settings} breadcrumbs={breadcrumbs}>
    <UmbracoGrid data={page} taggedContent={taggedNodes?.items} />
  </Layout>;

export async function getStaticPaths({
  preview,
  locales,
}: {
  preview: boolean;
  locales: string[];
}) {
  const allPages = await getAllPages({ locales, preview, take: 80 });

  const paths = allPages
    .map((p) => ({
      locale: p.locale,
      params: { slug: urlToNextPath(p?.route?.path || '/', p.locale) },
    }))
    .filter((p) => p.params.slug.length > 0) // filter out empty paths
    .filter((p) => p.params.slug.length < 2); // filter out non root paths

  return {
    paths,
    fallback: 'blocking',
  };
}

export async function getStaticProps({
  params,
  preview,
  previewData,
  locale,
}: {
  params: Params;
  preview: boolean;
  previewData: { locale: string } | null;
  locale: string;
}) {
  const language = previewData?.locale || locale;
  const path = nextPathToUrl(params.slug);

  const umbracoData = await getPageData({ path, locale: language, preview });

  if (!umbracoData.page?.id) {
    return {
      notFound: true,
    };
  }
  return {
    props: {
      data: umbracoData,
      preview: preview || null,
    },
    revalidate: 60,
  };
}

export default Slug;
